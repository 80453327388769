import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  scroll-snap-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 5% 0;
  position: relative;
  margin-top: 8%;
  transform: 0.5s ease-in-out;

  @media (max-width: 991px) {
    align-items: center;
    margin-top: 15%;
  }
`;

export const Title = styled.h1`
  font-size: 80px;
  color: #f2f4f6;
  font-weight: 300;
  font-family: "GFS Didot", serif;
  transform: 0.5s ease-in-out;
  text-align: center;

  @media (max-width: 945px) {
    font-size: 70px;
  }

  @media (max-width: 781px) {
    font-size: 50px;
    margin-top: 10%;
  }
`;

export const Form = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1em;
  transform: 0.5s ease-in-out;

  @media (max-width: 781px) {
    width: 350px;
    font-size: 0.8em;
  }
`;

export const Input = styled.input`
  padding: 15px;
  background-color: #e8e6e6;
  border-radius: 5px;
  border: none;

  @media (max-width: 992px) {
    padding: 7px;
  }
`;

export const TextArea = styled.textarea`
  padding: 15px;
  background-color: #e8e6e6;
  border-radius: 5px;
  border: none;

  @media (max-width: 992px) {
    padding: 7px;
  }
`;

export const SendButton = styled.button`
  background-color: #d4adfc;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #daf7f5;
  font-weight: bold;
  transition: all 0.2s linear 0s;
  position: relative;
  z-index: 100000000000000;
  cursor: pointer;
  font-family: "Libre Baskerville", serif;

  &:hover {
    color: #4169df;
  }
`;


