import { styled } from "styled-components";

// Hero main component
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  scroll-snap-align: center;
  overflow-y: scroll;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
  padding: 5% 0;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 10%;

  @media (max-width: 781px) {
    gap: 0;
  }
`;

export const BottomContainer = styled.div`
  margin-top: 50px;
`;

export const Title = styled.h1`
  font-size: 80px;
  color: #f2f4f6;
  font-weight: 300;
  font-family: "GFS Didot", serif;
  transform: 0.5s ease-in-out;
  text-align: center;

  @media (max-width: 945px) {
    font-size: 70px;
  }

  @media (max-width: 781px) {
    font-size: 50px;
  }
`;

export const Description = styled.p`
  font-size: 1.2em;
  color: #f5f5f5;
  opacity: 0.75;
  line-height: 1.8;
  text-align: center;

  @media (max-width: 945px) {
    font-size: 1em;
  }

  @media (max-width: 781px) {
    font-size: 15px;
  }
`;

export const ButtonList = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Button = styled.button`
  //Smaller screen styles

  font-size: 1em;
  font-weight: 400;
  border: 1px solid #4169df;
  padding: 3px 5px;
  position: relative;
  width: 180px;
  font-family: "Libre Baskerville", serif;
  border-radius: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  background-color: #d4adfc;
  color: #4169df;
  transition: 0.3s ease-in-out;

  &:hover {
    @media (max-width: 999px) {
      color: #f1f4f6;
      background-color: #4169df;
    }
  }

  @media (max-width: 945px) {
    font-size: 1em;
  }

  @media (max-width: 781px) {
    font-size: 12px;
    width: 130px;
    padding: 5px;
  }

  // Large screen hover effect

  @media only screen and (min-width: 992px) {
    background-color: transparent;
    transition: 0.5s ease-in-out;
    color: #d4adfc;

    &:hover {
      color: #4169df;
      border-color: #d4adfc;

      &::before {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }

    &::before {
      content: "";
      width: 0%;
      height: 100%;
      position: absolute;
      background-color: #d4adfc;
      left: 0;
      top: 0;
      z-index: -1;
      transition: 0.5s ease-in-out;
      border-radius: 20px;
    }
  }
`;

// Works component

export const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  align-items: center;
  padding: 0;
  list-style: none;
  grid-auto-rows: min-content;

  @media (max-width: 781px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
`;

export const ListItem = styled.li`
  padding: 20px;
  font-size: 50px;
  font-weight: bold;
  cursor: pointer;
  color: transparent;
  -webkit-text-stroke: 0.0625rem #f1f4f6;
  opacity: 0.75;
  position: relative;
  transition: 1s ease-in-out;

  ${(props) =>
    props.isSelected &&
    `
    @media (max-width: 781px) {
      color: #f1f4f6;
      opacity: 1;
      -webkit-text-stroke: none;
    }
  `}

  &::after {
    content: "${(props) => props.text}";
    position: absolute;
    left: 0;
    right: 0;
    padding: 20px;
    color: #feeae8;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    display: none;
    height: 100%;
    line-height: 100%;
    transform: translateY(-7%);

    @media (max-width: 945px) {
      transform: translateY(-10%);
    }

    @media (max-width: 781px) {
      content: "";
    }
  }

  &:nth-child(odd) {
    text-align: left;

    &::after {
      left: 0;
      text-align: left;
      transform-origin: left;
    }
  }

  &:nth-child(even) {
    text-align: right;

    &::after {
      text-align: right !important;
    }
  }

  &:hover {
    opacity: 1;
    &::after {
      animation: moveText 0.3s linear both;
      display: inline;

      @keyframes moveText {
        to {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 945px) {
    font-size: 40px;
  }

  @media (max-width: 781px) {
    font-size: 35px;
    padding: 5px;
    opacity: 0.75;
    font-weight: 400;
  }
`;

export const Explore = styled.div`
  font-family: "Libre Baskerville", serif;
  opacity: 0.75;
  text-align: center;
  grid-column: span 2;
  font-size: 1.2em;
  color: #f5f5f5;
  opacity: 0.7;
  transform: 0.3s ease-in-out;

  @media (max-width: 945px) {
    font-size: 1em;
  }

  @media (max-width: 781px) {
    font-size: 12px;
  }
`;

export const WorksDesc = styled.div`
  margin-left: 20px;
  background-color: #060816 !important;
  z-index: 98888888;
  color: #829dea;
  opacity: 1;
  font-size: 19.2px;
  text-align: justify;

  @media (max-width: 945px) {
    font-size: 16px;
  }

  @media (max-width: 781px) {
    margin: 0;
    font-size: 15px;
    padding: 10px 15px;
    width: 130% !important;
    left: -10% !important;
    text-align: justify;
  }
`;
