import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faXTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const SocialIcon = ({ icon, url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon className="nav-fa" icon={icon} size="lg" />
  </a>
);

const NavBar = ({ navigate, activeLink, setActiveLink }) => {
  const [isToggled, setIsToggled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();

  const socialLinks = [
    {
      icon: faLinkedinIn,
      url: "https://www.linkedin.com/in/claudia-church-2906b0243/",
    },
    { icon: faGithub, url: "https://github.com/claudiaacchurch" },
    { icon: faEnvelope, url: "mailto:claudiachurch00@gmail.com" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
      return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);  

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setActiveLink("home");
        break;
      case "/portfolio":
        setActiveLink("portfolio");
        break;
      case "/contact":
        setActiveLink("contact");
        break;
      default:
        setActiveLink("home");
        break;
    }
  }, [location.pathname]);  

  return (
    <Navbar expand="lg" className="navbar-dark">
      <Container>
        <Navbar.Brand
          href="#home"
          onClick={() => {
            navigate("/");
          }}
        >
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={isToggled ? "toggled" : ""}
          onClick={() => setIsToggled((prev) => !prev)}
          style={{ backgroundColor: "#D4ADFC" }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {["home", "portfolio", "contact"].map((link) => (
              <Nav.Link
                key={link}
                href={`#${link}`}
                className={
                  activeLink === link ? "active navbar-link" : "navbar-link"
                }
                onClick={() => {
                  setActiveLink(link);
                  if (link === "home") navigate("/");
                  else if (link === "portfolio") navigate("/portfolio");
                  else if (link === "contact") navigate("/contact");
                }}
              >
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </Nav.Link>
            ))}
          </Nav>
          <span className="navbar-text">
            {windowWidth >= 992 ? (
              <div className="socials">
                {socialLinks.map((social, index) => (
                  <SocialIcon key={index} icon={social.icon} url={social.url} />
                ))}
              </div>
            ) : (
              ""
            )}
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
