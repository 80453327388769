import React, { useState } from "react";
import "../../App.css";
import NavBar from "../Navbar/NavBar";
import Works from "./Works";
import {
  Section,
  Container,
  TopContainer,
  Title,
  Description,
  ButtonList,
  Button,
  BottomContainer,
} from "./HeroStyles";

export const Hero = ({ navigate, activeLink, setActiveLink }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <Section>
      <NavBar
        navigate={navigate}
        activeLink={activeLink}
        setActiveLink={setActiveLink}
      />
      <Container>
        <TopContainer>
          <Title>
            <span className="span-c">Claudia Church</span>
          </Title>
          <Description>~ Full Stack Software Developer ~</Description>
          <ButtonList>
            <Button
              onClick={() => {
                navigate("/portfolio");
                setSelectedItem("portfolio");
              }}
              isSelected={selectedItem === "portfolio"}
            >
              Portfolio{" "}
              <span role="img" aria-label="woman technologist">
                👩‍💻
              </span>
            </Button>
            <Button
              onClick={() => {
                navigate("/contact");
                setSelectedItem("contact");
              }}
              isSelected={selectedItem === "contact"}
            >
              Contact 📧
            </Button>
          </ButtonList>
        </TopContainer>
        <BottomContainer>
          <Works />
        </BottomContainer>
      </Container>
    </Section>
  );
};
