import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, Routes, Route } from "react-router-dom";
import React from "react";
import { useState } from "react";
import { Hero } from "./components/Hero/Hero";
import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/Contact/Contact";

function App() {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("home");
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Hero
            navigate={navigate}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
          />
        }
      />
      <Route
        path="/portfolio"
        element={
          <Portfolio
            navigate={navigate}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
          />
        }
      />
      <Route
        path="/contact"
        element={
          <Contact
            navigate={navigate}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
          />
        }
      />
    </Routes>
  );
}

export default App;
