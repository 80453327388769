import React, { useRef, useState, useEffect } from "react";
import NavBar from "../Navbar/NavBar";
import {
  Section,
  Container,
  Title,
  Form,
  Input,
  TextArea,
  SendButton,
} from "./ContactStyles";

const Contact = ({ navigate, activeLink, setActiveLink }) => {
  const ref = useRef();
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [formMessage, setFormMessage] = useState(""); 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim() || !email.trim() || !message.trim()) {
      setFormMessage("All fields are required!");
      setSuccess(false);
      return;
    }

    if (!isValid) {
      setFormMessage("Please enter a valid email.");
      setSuccess(false);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('http://localhost:5000/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      const result = await response.json();

      if (result.success) {
        setFormMessage("Thanks for your message, I'll get back to you as soon as I can 👩‍💻");
        setSuccess(true);
      } else {
        setFormMessage("Failure sending email, please try again later or contact me directly at claudicahurch00@gmail.com");
        setSuccess(false);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setFormMessage("An unexpected error occurred. Please try again later.");
      setSuccess(false);
    } finally {
      setLoading(false); 
    }
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsValid(isValidEmail(enteredEmail));
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  return (
    <Section>
      <NavBar
        navigate={navigate}
        activeLink={activeLink}
        setActiveLink={setActiveLink}
      />
      <Container>
        <Title>
          <div className="span-c">Get in touch!</div>
        </Title>
        <Form ref={ref} onSubmit={handleSubmit}>
          <Input
            placeholder="Name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder="Email"
            type="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
          <TextArea
            placeholder="Write your message"
            name="message"
            rows={windowWidth <= 991 ? 4 : 7}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
           <SendButton className="send-btn" type="submit" disabled={loading}>
            {loading ? "Sending..." : "Send"}
          </SendButton>
        </Form>
        {formMessage && !loading && (
          <p style={{ color: success ? "green" : "red" }}>{formMessage}</p>
        )}
      </Container>
    </Section>
  );
};

export default Contact;
