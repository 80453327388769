import React, { useEffect, useRef } from "react";
import "../../App.css";
import NavBar from "../Navbar/NavBar";
import {
  Section,
  Container,
  TopContainer,
  Title,
} from "./PortfolioStyles";
import "../../App.css";

const Portfolio = ({ navigate, activeLink, setActiveLink }) => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    elementsRef.current.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsRef.current.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  return (
    <Section id="portfolio">
      <NavBar
        navigate={navigate}
        activeLink={activeLink}
        setActiveLink={setActiveLink}
      />
      <Container>
        <TopContainer>
          <Title>
            <span className="span-c">Things I've worked on.</span>
          </Title>
        </TopContainer>
        <div className="bottom-grid">
          {[
            { title: "NETHERCOTT DRONES", desc: "Professional business website", img: "/ndnew.png"},
            { title: "ONLINE BOOK STORE", desc: "Complete with search, ratings, shopping cart & payment integration.", img: "/Li4.png" },
            { title: "AUTOMATED BOOKING SYSTEM", desc: "Automated events in google calendar, blocking out booked times.", img: "/rr5.png" },
            { title: "INFINITY TRAILS", desc: "Text Based adventure game integrated with GPT to determine the story.", img: "/InfinityTrails.png" },
            { title: "BUSINESS MANAGER APP", desc: "Including automated invoice creation and order management.", img: "/automately.png" },
            { title: "NUANCE", desc: "Minimalist clothing site with log in functionality", img: "/nuance" },
            { title: "MOONBASE TELEGRAM BOT", desc: "Scans for smart contract deployments on Basenet that fit a certain criteria for investment with direct links to buy the crypto token.", img: "tele.jpeg" }
          ].map((item, index) => (
            <div
              className="portfolio-div fade-in"
              key={index}
              ref={(el) => (elementsRef.current[index] = el)}
            >
              <h1 className="container-h1">{item.title}</h1>
              <p>{item.desc}</p>
              <img src={item.img} alt={item.title} loading="lazy" />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Portfolio;