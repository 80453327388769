import React, { useState } from "react";
import { List, Explore, ListItem, WorksDesc } from "./HeroStyles";

const data = [
  {
    item: "Software Dev",
    description:
      "I can build custom solutions, for example, I've built a Telegram chatbot using Basenet, which monitors and alerts users of new smart contract deployments that fit specific criteria.",
  },
  {
    item: "Ecommerce",
    description:
      "I build business-centric sites equipped with various functionalities, such as automatic booking services, user login, shopping cart and integrated payment systems.",
  },
  {
    item: "AI Integration",
    description:
      "I've built various services integrated with OpenAI's Chat GPT API. I have crafted a game with chatbot functionality and I've built an AI code review service for other developers.",
  },
  {
    item: "UI/UX design",
    description:
      "My work prioritises user experience, focused on responsive design. I'm proficient in various design frameworks and can employ lively animations.",
  },
];

const Works = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [showExplore, setShowExplore] = useState(true);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    if (expandedItem === index) {
      setExpandedItem(null);
      if (index >= data.length - 2) {
        setShowExplore(true);
      }
    } else {
      setExpandedItem(index);
    }
    if (index >= data.length - 2) {
      setShowExplore(false);
    } else {
      setShowExplore(true);
    }
  };

  return (
    <List>
      <Explore
        style={{
          opacity: expandedItem !== null ? 0.2 : 0.75,
        }}
      >
        Explore what I do ...
      </Explore>
      {data.map((entry, index) => (
        <div style={{ position: "relative" }} key={entry.item}>
          <ListItem
            text={entry.item}
            onClick={() => handleItemClick(index)}
            isSelected={selectedItem === index}
          >
            {entry.item}
          </ListItem>
          {expandedItem === index && (
            <WorksDesc
              style={{
                position: "absolute",
                width: "90%",
                bottom: index >= data.length - 2 ? "100%" : "auto",
                top: index >= data.length - 2 ? "auto" : "100%",
              }}
            >
              {entry.description}
            </WorksDesc>
          )}
        </div>
      ))}
    </List>
  );
};

export default Works;
