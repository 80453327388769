import { styled } from "styled-components";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  scroll-snap-align: center;
  overflow-y: scroll;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  width: 80%;
  margin-top: 8%;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 0 5% 0;

  @media (max-width: 991px) {
    gap: 0;
    padding: 0 0 10% 0;
  }
`;

export const Title = styled.div`
  font-size: 80px;
  color: #f2f4f6;
  font-weight: 300;
  font-family: "GFS Didot", serif;
  transform: ease-in-out;
  margin-top: 3%;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 70px;
    margin-top: 10%;
  }

  @media (max-width: 639px) {
    font-size: 50px;
    margin-top: 20%;
  }
`;

export const Desc = styled.div`
  color: #aed9fd;
  font-size: 17px;
  font-family: "Libre Baskerville", serif;
  width: 100%;
  text-align: left;

  @media (max-width: 991px) {
    font-size: 17px;
  }

  @media (max-width: 639px) {
    font-size: 11.2px;
  }
`;

export const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  overflow-x: hidden;
`;
